import flatpickr from "flatpickr";
import instantsearch from 'instantsearch.js/es/index';
import {
  searchBox,
  configure,
  hits
} from 'instantsearch.js/es/widgets';
import {
  instantMeiliSearch
} from '@meilisearch/instant-meilisearch';
import { AuthStatus, Status } from "../../common/auth";

export const formNameSearch = () => {
  const search = instantsearch({
    indexName: "ciks3",
    // @ts-ignore
    searchClient: instantMeiliSearch(
      "https://search.stockocean.com",
      "rje5krn3vgn0nutxRQX",
      {
        placeholderSearch: false,
      }
    )
  });
  search.addWidgets([
    searchBox({
      container: "#screener-searchbox",
      // showReset: false,
      // showSubmit: false,

      cssClasses: {
        input: 'screener-input',
        reset: 'screener-reset',
        resetIcon: 'screener-reset-icon',
      },
    }),
    configure({ hitsPerPage: 8 }),
    hits({
      container: "#screener-hits",
      cssClasses: {
        root: 'screener-hits-root',
        emptyRoot: `search-empty-screener`,
        item: `search-item-screener`,
      },
      templates: {
        // @ts-ignore
        empty(results, { html }) {
          html``;
        },
        item: (hit, { html, components }) =>
          hit.symbol ? html`
          <a style="display: flex; align-items: center; text-decoration: none;" class="screener-search-result" href="#" data-type="pc" data-cik="${hit.cik}">
            <img src="https://cdn.stockocean.com/logo/${hit.logoid}.svg" style="width: 16px; height: 16px; margin-right: 8px;" />
            <span class="screener-search-result-name">
              ${hit.name}
            </span>
            <span style="margin-left: auto;">
              ${hit.symbol}
            </span>
          </a>
        ` : html`
          <a class="screener-search-result" href="#" data-type="entity" data-cik="${hit.cik}">${hit.name}</a>
        `
      }
    })
  ]);
  search.start();

  // @ts-ignore
  const namePersist = document.querySelector('#form_name_persist')?.dataset?.name;
  if (namePersist) {
    // @ts-ignore
    document.querySelector('.screener-input').value = namePersist;
    const intervalId = setInterval(() => {
      // @ts-ignore
      if (!document.querySelector('.screener-input').value) {
        // @ts-ignore
        document.querySelector('.screener-input').value = namePersist;

        const dom = document.querySelector('.screener-input');
        // @ts-ignore
        dom?.setAttribute('data-type', document.querySelector('#form_name_persist')?.dataset?.type);
        // @ts-ignore
        dom?.setAttribute('data-cik', document.querySelector('#form_name_persist')?.dataset?.cik);

        clearInterval(intervalId);
      }
    }, 10);
  }

  document.body.addEventListener('click', (e) => {
    const target = e.target;
    const screenerResultClass = 'screener-search-result';

    // @ts-ignore
    const closestScreenerResult = target?.closest(`.${screenerResultClass}`);

    if (closestScreenerResult) {
        e.preventDefault();

        const dataType = closestScreenerResult.dataset.type;

        const dom = document.querySelector('.screener-input');
        const textContent = (dataType === 'pc')
            ? closestScreenerResult.querySelector('.screener-search-result-name')?.textContent
            : closestScreenerResult.textContent;

        if (search.helper) {
            search.helper.setQuery(textContent).search();
        }

        dom?.setAttribute('data-type', dataType);
        dom?.setAttribute('data-cik', closestScreenerResult.dataset.cik);

        // @ts-ignore
        document.querySelector('.screener-hits-root').style.display = 'none';
    }
  });

  document.querySelector('.screener-reset')?.addEventListener('click', () => {
    const dom = document.querySelector('.screener-input');
    dom?.removeAttribute('data-type');
    dom?.removeAttribute('data-cik');
  });

  // const inputElement = document.querySelector('.ais-SearchBox-input.screener-input');
  // inputElement?.addEventListener('focus', () => {
  //   // @ts-ignore
  //   document.querySelector('.screener-hits-root').style.display = 'block';
  // });
  // inputElement?.addEventListener('blur', () => {
  //   // @ts-ignore
  //   document.querySelector('.screener-hits-root').style.display = 'none';
  // });

  let curr = null;
  const prefixClassName = 'screener';
  document.querySelector(`.screener-input`)?.addEventListener('keydown', (e) => {
    // @ts-ignore
    if (e.code == 'Enter') {
      // e.preventDefault();
      // e.stopPropagation();
      if (curr != null) {
        const dom = document.querySelector(`.search-item-${prefixClassName}.search-highlighted-${prefixClassName}`)?.children[0];
        // console.log(dom?.textContent);
        // @ts-ignore
        search.helper?.setQuery(dom?.textContent).search();
        let screenerDom = document.querySelector('.screener-input');
        // @ts-ignore
        screenerDom?.setAttribute('data-type', dom?.dataset?.type);
        // @ts-ignore
        screenerDom?.setAttribute('data-cik', dom?.dataset?.cik);
        // @ts-ignore
        document.querySelector('.screener-hits-root').style.display = 'none';
      }
      // @ts-ignore
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      if (document.querySelectorAll(`.search-empty-alert`).length == 0) {
        const resultCount = document.querySelectorAll(`.search-item-${prefixClassName}`).length;
        // @ts-ignore
        if (e.key === 'ArrowDown') {
          // @ts-ignore
          curr = curr != null ? (curr + 1) % resultCount : 0;
        } else {
          // @ts-ignore
          curr = curr != null ? Math.max((curr - 1) % resultCount, 0) : resultCount - 1;
        }

        document
          .querySelectorAll(`.search-item-${prefixClassName}`)
          .forEach((element) => {
            element.classList.remove(`search-highlighted-${prefixClassName}`);
        });

        // @ts-ignore
        document.querySelector(`.search-item-${prefixClassName}:nth-of-type(${curr + 1})`)?.classList.add(`search-highlighted-${prefixClassName}`);
      }
    // @ts-ignore
    }
    //  else if (e.key == 'Escape') {
      // e.preventDefault();
      // console.log('here');
      // @ts-ignore
      // e.target.blur();
      // @ts-ignore
      // document.querySelector('.screener-hits-root').style.display = 'block';
    // }
  });

  document.querySelector(`.screener-input`)?.addEventListener('blur', () => {
    curr = null;
  });
}

export enum PremiumAlertType {
  sector, tenBFiveOne, iq, marketCap, alert, alertCountLimit
}
export const showPremiumAlert = (type: PremiumAlertType) => {
  let str = '';
  switch (type) {
    case PremiumAlertType.sector:
      str = 'Filtering by Sector/Industry is a premium feature. Please upgrade to use this feature.';
      break;
    case PremiumAlertType.tenBFiveOne:
      str = 'Excluding 10b5-1 is a premium feature. Please upgrade to use this feature.';
      break;
    case PremiumAlertType.iq:
      str = 'Filtering by IQ is a premium feature. Please upgrade to use this feature.';
      break;
    case PremiumAlertType.marketCap:
      str = 'Filtering by Market Capitalization is a premium feature. Please upgrade to use this feature.';
      break;
    case PremiumAlertType.alert:
      str = 'Alerts beyond "Name" is a premium feature. Please upgrade to use this feature.';
      break;
    case PremiumAlertType.alertCountLimit:
      str = 'You can only add up to 3 basic alerts. Please upgrade to add unlimited alerts.';
      break;
  }

  if (!str) {
    return;
  }
  const result = window.confirm(str);
  if (result) {
    window.location.href = 'https://www.stockocean.com/pricing';
  }
}

export const sectorIndustry = () => {
  const dataDom = document.getElementById('sector_industry_data');
  //@ts-ignore
  const data = JSON.parse(dataDom.getAttribute('data'));
  const getUniqueValues = (array, key) => {
      return [...new Set(array.map(item => item[key]))];
  }

  const populateOptions = (selectElement, options) => {
      const select = document.getElementById(selectElement);
        //@ts-ignore
      select.innerHTML = "";

      options.forEach(option => {
          const optionElement = document.createElement("option");
          optionElement.value = option;
          optionElement.text = option;
            //@ts-ignore
          select.appendChild(optionElement);
      });
  }

  const setDefault = () => {
      //@ts-ignore
    const defaultSector = dataDom.getAttribute('sector');
    if (defaultSector) {
        //@ts-ignore
      document.getElementById("form_sector").value = getUniqueValues(data, "sector").find(item => item.toLowerCase() === defaultSector);
    }
      //@ts-ignore
    const defaultIndustry = dataDom.getAttribute('industry');
    if (defaultIndustry) {
        //@ts-ignore
      const selectedSector = document.getElementById("form_sector").value;
      const industryOptions = ['Industry', ...getUniqueValues(data.filter(item => item.sector === selectedSector), "industry")];
      populateOptions("form_industry", industryOptions);
        //@ts-ignore
      document.getElementById("form_industry").value = getUniqueValues(data, "industry").find(item => item.toLowerCase() === defaultIndustry);
        //@ts-ignore
      document.getElementById("form_industry").disabled = false;
    }
  }

  const updateIndustrySelector = () => {
    //@ts-ignore
    const selectedSector = document.getElementById("form_sector").value;
    const industryOptions = ['Industry', ...getUniqueValues(data.filter(item => item.sector === selectedSector), "industry")];
    populateOptions("form_industry", industryOptions);
    //@ts-ignore
    document.getElementById("form_industry").disabled = false;
  }

  const sectorOptions = ['Sector', ...getUniqueValues(data, "sector")];
  populateOptions("form_sector", sectorOptions);

  setDefault();

  //@ts-ignore
  document.getElementById("form_sector").addEventListener("change", () => {
    const status = AuthStatus.status();
    switch (status) {
    case Status.loggedOut:
      updateIndustrySelector();
      break;
    case Status.basic:
      const sectorOptions = ['Sector', ...getUniqueValues(data, "sector")];
      populateOptions("form_sector", sectorOptions);
      showPremiumAlert(PremiumAlertType.sector);
      break;
    case Status.premium:
      updateIndustrySelector();
      break;
    }
  });
}

export const datePicker = () => {
  flatpickr('#form_filing_date', {
    mode: 'range',
    dateFormat: 'Y/m/d',
    locale: {
      rangeSeparator: ' - '
    },
  });
  flatpickr('#form_trade_date', {
    mode: 'range',
    dateFormat: 'Y/m/d',
    locale: {
      rangeSeparator: ' - '
    },
  });
}

export const formBehavior = () => {
  document.getElementById('form_ots_off')?.addEventListener('change', () => {
    // @ts-ignore
    const isChecked = document.getElementById('form_ots_off')?.checked;
    Array.from(document.querySelectorAll('.form_ots_off_sub')).forEach(e => {
      // @ts-ignore
      e.checked = isChecked;
    });
  });

  window.addEventListener('pageshow', () => {
    document.getElementById('form_loading')?.classList.add('d-none');
  });

  const status = AuthStatus.status();
  document.getElementById('a10b5')?.addEventListener('change', (e) => {
    const checkbox = e.target;
    switch (status) {
      case Status.loggedOut:
        break;
      case Status.basic:
        // @ts-ignore
        checkbox.checked = !checkbox.checked;
        showPremiumAlert(PremiumAlertType.tenBFiveOne);
        break;
      case Status.premium:
        break;
    }
  });
  ['r', 'fa', 'ri', 'a_e'].forEach(e => {
    document.getElementById(`form_iq_${e}`)?.addEventListener('change', (e) => {
      const checkbox = e.target;
      switch (status) {
        case Status.loggedOut:
          break;
        case Status.basic:
          // @ts-ignore
          checkbox.checked = !checkbox.checked;
          showPremiumAlert(PremiumAlertType.iq);
          break;
        case Status.premium:
          break;
      }
    });
  });
  [document.getElementById('form_mc_min'), document.getElementById('form_mc_max')].forEach(e => {
    e?.addEventListener('change', (e) => {
      const input = e.target;
      switch (status) {
        case Status.loggedOut:
          break;
        case Status.basic:
          // @ts-ignore
          input.value = '';
          showPremiumAlert(PremiumAlertType.marketCap);
          break;
        case Status.premium:
          break;
      }
    });
  });
}

export const formReset = (baseUrl: string) => {
  let isTriggered = false;

  document.getElementById('form-reset')?.addEventListener('click', () => {
    if (isTriggered) { return }
    isTriggered = true;
    const dom = document.querySelector('.screener-input');
    dom?.removeAttribute('data-type');
    dom?.removeAttribute('data-cik');

    const container = document.getElementById('screener-form');
    Array.from(container?.querySelectorAll('input') ?? []).forEach((input) => {
      if (input.type === 'checkbox') {
        // @ts-ignore
        input.checked = false;
      } else if (input.type != 'submit') {
        // @ts-ignore
        input.value = null;
      }
    });

    // @ts-ignore
    document.getElementById('form_sector').value = 'Sector';
    // @ts-ignore
    document.getElementById('form_industry').value = 'Industry';
    // @ts-ignore
    document.getElementById('form_industry').disabled = true;

    window.location.href = formSearchParams(baseUrl).url;

    isTriggered = false;
  });
}

export const formSearchParams = (baseUrl: string): { param: URLSearchParams, url: string } => {
  const url = new URL(baseUrl);

  // name
  const dom = document.querySelector('.screener-input');
  // @ts-ignore
  const cik = dom.dataset.cik;
  // @ts-ignore
  const type = dom.dataset.type;
  if (cik && type) {
    url.searchParams.append('cik', cik);
    url.searchParams.append('ct', type);
  }

  // @ts-ignore
  const ots = ['off', 'cob', 'ceo', 'pres', 'coo', 'cfo', 'gc', 'dir', 'ten','o'].map(e => document.querySelector(`input[name="ots_${e}"]:checked`) ? e : null).filter(e => e).join(',');
  if (ots) {
    url.searchParams.append('ots', ots);
  }

  // @ts-ignore
  const otc = document.querySelector('#form_owner_title_custom')?.value;
  if (otc != null && otc != '') {
    url.searchParams.append('otc', otc.trim());
  }

  // @ts-ignore
  const sector = document.querySelector("#form_sector")?.value;
  if (sector != null && sector!= 'Sector') {
    url.searchParams.append('sector', sector.toLowerCase());
  }
  // @ts-ignore
  const industry = document.querySelector("#form_industry")?.value;
  if (industry != null && industry!= 'Industry') {
    url.searchParams.append('industry', industry.toLowerCase());
  }

  // @ts-ignore
  const fd = document.querySelector('#form_filing_date')?.value;
  if (fd != null && fd != '') {
    fd.split('-').forEach((v, i) => {
      switch (i) {
        case 0:
          url.searchParams.append('fd_f', v.trim().split('/').join('-'));
          break;
        case 1:
          url.searchParams.append('fd_t', v.trim().split('/').join('-'));
          break;
        default:
          break;
      }
    });
  }

  // @ts-ignore
  const td = document.querySelector('#form_trade_date')?.value;
  if (td != null && td != '') {
    td.split('-').forEach((v, i) => {
      switch (i) {
        case 0:
          url.searchParams.append('td_f', v.trim().split('/').join('-'));
          break;
        case 1:
          url.searchParams.append('td_t', v.trim().split('/').join('-'));
          break;
        default:
          break;
      }
    });
  }

  // @ts-ignore
  const tcs = ['p', 's', 'a', 'd', 'g', 'f', 'm', 'x', 'c', 'w'].map(e => document.querySelector(`input[name="tcs_${e}"]:checked`) ? e : null).filter(e => e).join(',');
  if (tcs) {
    url.searchParams.append('tcs', tcs);
  }

  // @ts-ignore
  if (document.querySelector('input[name="a10b5"]:checked')?.value) {
    url.searchParams.append('a10b5', '1');
  }

  // @ts-ignore
  const iq = ['r', 'fa', 'ri', 'a_e'].map(e => document.querySelector(`input[name="iq_${e}"]:checked`) ? e : null).filter(e => e).join(',');
  if (iq) {
    url.searchParams.append('iq', iq);
  }

  [
    { key: 'p', name: 'price', },
    { key: 'q', name: 'quantity', },
    { key: 'c', name: 'change', },
    { key: 'v', name: 'value', },
  ].forEach(({ key, name }) => {
    // @ts-ignore
    const min = document.querySelector(`#form_${name}_min`)?.value;
    // @ts-ignore
    const max = document.querySelector(`#form_${name}_max`)?.value;

    if (min != null && min != '') {
      url.searchParams.append(`${key}_min`, min);
    }
    if (max != null && max != '') {
      url.searchParams.append(`${key}_max`, max);
    }
  });

  // @ts-ignore
  const min_owned = document.querySelector(`#form_owned_min`)?.value;
  // @ts-ignore
  const max_owned = document.querySelector(`#form_owned_max`)?.value;
  if (min_owned != null && min_owned != '') {
    url.searchParams.append('o_min', min_owned);
  }
  if (max_owned != null && max_owned != '') {
    url.searchParams.append('o_max', max_owned);
  }

  // @ts-ignore
  const min_mc = document.querySelector(`#form_mc_min`)?.value;
  // @ts-ignore
  const max_mc = document.querySelector(`#form_mc_max`)?.value;
  if (min_mc != null && min_mc != '') {
    url.searchParams.append('mc_min', min_mc);
  }
  if (max_mc != null && max_mc != '') {
    url.searchParams.append('mc_max', max_mc);
  }

  return {
    param: url.searchParams,
    url: url.toString(),
  };
}

export const formShowLoading = () => {
  const loadingTextElement = document.getElementById('form_loading_text') as HTMLDivElement;
  const dots = ['.', '..', '...', ''];
  let index = 0;

  setInterval(() => {
    if (loadingTextElement) {
      loadingTextElement.textContent = 'Loading Results' + dots[index];
    }
    index = (index + 1) % dots.length;
  }, 500);

  document.getElementById('form_loading')?.classList.remove('d-none');
}
